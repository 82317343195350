// i18next-extract-mark-ns-start products-fearce-tafice

import { FEARCE_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductFearcePage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation();
	const icon = getImage(data.icon.childImageSharp.gatsbyImageData);
	const inCylinder_image = getImage(data.inCylinder_image.childImageSharp.gatsbyImageData);
	const valve_image = getImage(data.valve_image.childImageSharp.gatsbyImageData);
	const pistonLiner_image = getImage(data.pistonLiner_image.childImageSharp.gatsbyImageData);
	const pistonCooling_image = getImage(data.pistonCooling_image.childImageSharp.gatsbyImageData);
	const cylinderBore_image = getImage(data.cylinderBore_image.childImageSharp.gatsbyImageData);

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="narrow"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					{icon && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={icon}
								alt="FEARCE-Vulcan"
								className="icon-image"
							/>
						</div>
					)}
					<Trans i18nKey="content_1">
						<p>
							FEARCE-Vulcan uses a unique iterative Finite Element (FE) solution that considers all the heat paths in detail
							to calculate the thermal boundary conditions of the power cylinder components using physical models and semi-empirical correlations,
							without the need for CFD analysis.
							This overcomes the limitation of database methods which are not predictive and delivers a reliable
							and accurate predictive tool for conclusive structural investigations.
						</p>

						<p>
							The iterative solution runs quickly enough to allow different design variants to be assessed for multiple operating conditions.
							The calculated temperatures and the corresponding deformations can then be used to predict piston secondary motion and ring dynamics
							and to better predict friction, wear and oil consumption of the power cylinder and the structural durability of the components.
						</p>

						<h2>Thermal modelling of more efficient and lower emission IC engines</h2>
						<p>
							As IC engines become smaller, often in a hybrid system, this requires higher peak cylinder pressures to improve fuel economy, which drives higher in-cylinder temperatures.
							Analytical tools are required to optimize the design to deliver more durable components, whilst minimising extensive testing to reduce product development costs.
						</p>
						<p>
							Thermal survey measurements are an important part of engine development and are used together with CFD to predict component temperatures.
							However, these measurements and analytical methods can be costly, time and labour intensive, and in the case of the measurements require a physical engine.
						</p>
						<p> FEARCE-Vulcan can be used early in product development before hardware exists reducing CFD and thermal survey costs making the engine development cycle more efficient.
							This new tool also meets the future challenges of ICE design and development,
							by accurately predicting the thermal loading and temperatures of an ICE quickly under multiple full-load and part-load conditions.

						</p>

						<h2>Thermal models</h2>

						<h3>In-cylinder</h3>
						<p>
							The in-cylinder model generates thermal boundary conditions considering the heat transfer between the cylinder gas
							and surrounding components exposed to the cylinder gas - namely the piston crown, cylinder wall, cylinder head flame face and the flame faces of each valve.
						</p>
					</Trans>
					{inCylinder_image && (
						<p>
							<GatsbyImage image={inCylinder_image} alt="In-cylinder model" />
						</p>
					)}

					<Trans i18nKey="content_2">
						<p> Piston crown and flame face boundary conditions are based on the propagation of burned gas during combustion.
							Spatial variation of gas temperature is determined using a two-zone combustion model by splitting the average
							temperature into temperatures of burnt and unburnt zones, to calculate for each point on the surface its exposure to each zone.
						</p>

						<h3>Valve</h3>
						<p>
							The valve model generates thermal boundary conditions from the convective heat transfer between the valve, cylinder and port gases,
							together with the heat transfer between contact interfaces, namely the seats and guides.
						</p>
					</Trans>

					{valve_image && (
						<p>
							<GatsbyImage image={valve_image} alt="Valve model" />
						</p>
					)}

					<Trans i18nKey="content_3">
						<p>
							All the necessary heat transfer mechanisms are considered including:
						</p>
						<ul>
							<li>Gas load on the valve head</li>
							<li>Gas flow over the back face</li>
							<li>Gas flow around the stem</li>
							<li>Oil film conduction to the guide</li>
							<li>Seat contact when the valve is closed</li>
							<li>Gas flow over the seat when the valve is open</li>
						</ul>
						<p>
							For those surfaces of the valve exposed to the gas in the cylinder,
							the in-cylinder model calculates the thermal loads and projects them onto the exposed valve surfaces.
							For sodium cooled valves the sodium temperature is simulated as part of the iterative solution.
						</p>

						<h3>Piston to liner interface</h3>
						<p>
							The piston to liner model considers ring dynamics and piston secondary motion to generate thermal boundary conditions,
							considering heat transfer between the piston rings and liner, as well as the piston skirt and liner.
						</p>
					</Trans>
					{pistonLiner_image && (
						<p>
							<GatsbyImage image={pistonLiner_image} alt="Piston liner interface model" />
						</p>
					)}

					<Trans i18nKey="content_4">
						<p>
							Piston secondary dynamics and piston rings' dynamic models are automatically generated from the supplied geometric and FE data
							to calculate clearances and heat transfer between the skirt, rings, piston grooves and liner.
							The heat transfer data are mapped and cycle-averaged onto the FE models of the piston and cylinder bore.
						</p>
						<h3>Piston cooling</h3>
						<p>
							The piston cooling model considers cooling mechanisms due to piston cooling jets, gallery cooling and crankcase oil.
						</p>
					</Trans>
					{pistonCooling_image && (
						<p>
							<GatsbyImage image={pistonCooling_image} alt="Piston cooling jet model" />
						</p>
					)}
					<Trans i18nKey="content_5">
						<p> The piston cooling jet model requires as input the position, angle of each jet and oil supply boundary conditions.
							The model considers the following features to derive the thermal boundary conditions:</p>
						<ul>
							<li>Impingement durations</li>
							<li>Spatial variation due to the jet spreading</li>
							<li>Varying impingement location</li>
							<li>Asymmetric flow due to compound impingement angle</li>
						</ul>

						<h3>Cylinder bore</h3>
						<p>
							Cycle-averaged boundary conditions are applied to the cylinder bore due to in-cylinder combustion and
							piston thermal loading via the ring pack and skirt, together with cooling due to the oil.
						</p>
					</Trans>
					{cylinderBore_image && (
						<p>
							<GatsbyImage image={cylinderBore_image} alt="Cylinder bore model" />
						</p>
					)}
					<Trans i18nKey="content_6">
						<p> Boundary conditions due to flow of the coolant are either applied as constant HTC
							and fluid temperature values to the surfaces defining the water jacket,
							or mapped from an isothermal CFD analysis of the coolant circuit.
						</p>
						<p>
							The effect of nucleate boiling is simulated as part of the iterative solution using a map of heat transfer coefficients dependent on the resultant wall temperature.
							In the locations where the resultant wall temperature is predicted above the boiling point,
							the heat transfer coefficient is adjusted (increased) and used for the next iteration.
						</p>

						<h2>Key features</h2>

						<ul>
							<li>All heat paths of the power cylinder are considered</li>
							<li>Dedicated graphical user interface</li>
							<li>Supports Diesel, gasoline, natural gas and hydrogen combustion</li>
							<li>Optionally CFD combustion boundary conditions can be included as part of the FEARCE-Vulcan process</li>
							<li>Automatic generation of piston and piston rings' dynamic models with appropriate defaults</li>
							<li>Support of sodium cooled valves</li>
							<li>Piston cooling due to piston cooling jets (PCJ) and those pistons with a gallery are considered</li>
							<li>The effect of nucleate boiling on the engine coolant side is included</li>
							<li>The Realis Run Distribution Manager (RDM) allows cases to be run in parallel
								delivering thermally efficient IC engines in ever-shorter timescales</li>
						</ul>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_Applications")}
						className="widget__sibling__pages">
						<AssetList>
							{FEARCE_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default ProductFearcePage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-fearce-tafice", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/fearce-hero.jpg" }) {
		...imageBreaker
	}
	icon: file(relativePath: { eq: "content/rs_fearce_vulcan.png" }) {
		...imageStandard
	}
	inCylinder_image: file(relativePath: { eq: "content/fearce-vulcan-in-cylinder.png" }) {
		...imageStandard
	}
	valve_image: file(relativePath: { eq: "content/fearce-vulcan-valves.png" }) {
		...imageStandard
	}
	pistonLiner_image: file(relativePath: { eq: "content/fearce-vulcan-piston-liner.png" }) {
		...imageStandard
	}
	pistonCooling_image: file(relativePath: { eq: "content/fearce-vulcan-piston-cooling.png" }) {
		...imageStandard
	}
	cylinderBore_image: file(relativePath: { eq: "content/fearce-vulcan-cylinder-bore.png" }) {
		...imageStandard
	}
}
`;
